<template>
  <div id="stripe-payment" class="flex flex-col h-full pt-10 pb-52 relative">
    <DJActionButton class="absolute top-8 left-10" :image="arrowBack" @click="handleBack"/>
    <form class="flex-grow overflow-y-auto"> 
      <div id="payment-element"></div>
      <div class="mt-4" id="link-authentication-element"></div>
      <div v-if="isLoading">
        <SimpleSpinner v-if="isLoading"/>
      </div>
    </form>

    <div class="fixed bottom-0 left-0 w-full px-10 py-6 bg-tp-black flex flex-col justify-center items-center rounded-t-xl selected-shadow z-20">
      <div class="flex flex-col gap-2">
        <SongCard v-if="isSongValid && songInfo !== null" :song="songInfo.songName" :artist="songInfo.artist" :image="songInfo.image" :payment="true" :votes=votes />
        <div class="w-80 flex flex-row p-2 justify-between font-extrabold leading-none text-tp-black">
          <p class="text-lg text-tp-yellow">Total a pagar</p>
          <p class="text-lg text-tp-yellow">{{ amount }}€</p>
        </div>
      </div>
      <button v-if="isAmountValid && isSongValid" @click="handleSubmit" class="w-80 py-3 px-4 bg-tp-yellow placeholder-tp-placeholder-black text-tp-black font-bold rounded-2xl">
        Confirmar Pago
      </button>
    </div>
  </div>
  <GenericInfoModal 
        v-if="cantAddSongModalVisible"
        :title="'No da tiempo'"
        :message="'Con menos de un minuto restante, al DJ no le daría tiempo a preparar tu canción'"
        :buttonText="'Entendido'"
        @onCloseModal="handleCloseCantAddSongModal" 
      />
</template>




<script>
import { loadStripe } from '@stripe/stripe-js';
import { ref, onMounted, computed } from 'vue';
import votingService from '@/service/votingService';
import SimpleSpinner from '@/components/Common/SimpleSpinner.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import { useRoute, useRouter } from 'vue-router';
import { trackInfo } from '../../service/musicService';
import SongCard from '../AddSong/SongCard.vue';
import GenericInfoModal from '../Common/GenericInfoModal.vue';
import createLoungeService from '@/service/loungeService';

export default {
  name: 'StripePayment',
  components: {
    DJActionButton,
    SimpleSpinner,
    SongCard,
    GenericInfoModal
  },
  props: {
    paymentData: Object
  },
  setup () {
    
    const elements = ref(null);
    const paymentMessage = ref("");
    const paymentMessageVisible = ref(false);
    const stripe = ref(null);
    const route = useRoute();
    const router = useRouter();
    const amount = route.params.votePlan;
    const amountStripe = route.params.votePlan*100;
    const loungeId = route.params.id;
    const flag = route.params.votationType;
    const songId = route.params.songId;
    let loungeVotingService = null;
    const isAmountValid = ref(amount > 0.98);
    const isSongValid = ref(false);
    const songInfo = ref(null);
    
    const isLoading = ref(false);
    const isButtonLoading = ref(false);

    const cantAddSongModalVisible = ref (false);
    const { fetchLoungeDetails } = createLoungeService();

    const votes = computed(() => {
      let floatAmount = parseFloat(amount);
      if (floatAmount === 1.49) {
        return 3;
      } else if (floatAmount === 4.99) {
        return 12;
      } else if (floatAmount === 9.99) {
        return 25;
      } else {
        return 0; 
      }
    });

    const generatePayment = async () => {
      loungeVotingService = votingService(loungeId)
      const data = {
        amount: amountStripe,
        songId: songId,
        songName: songInfo.value.songName,
        artist: songInfo.value.artist,
        image: songInfo.value.image
      }
      let response;
      if (flag === 'voting') {
        response = await loungeVotingService.votingPayment(data);
      } else if (flag === 'ranking') {
        response = await loungeVotingService.rankingPayment(data);
      }
      
      return response.clientSecret
    };

    const initialize = async () => {
      if (!isAmountValid.value) return;

      isSongValid.value = await checkSongValidity(songId);

      if (!isSongValid.value) return;

      //TODO: comprobar qué se quiere hacer cuando la amount y la song no sean válidas, retornar a la lounge?

      stripe.value = await loadStripe("pk_test_51Ope8FHdxAPIqguouk0aGbUpvBHbY7r0vuKmj3s3owFeip3lzUYprfbO2L4GRETz1lAexLUebIIlSAKTMZDspxmP004F5faPYU");

      const appearance = {
        theme: 'night',
        variables: {
          colorPrimary: '#F9F871',
          colorBackground: '#0D0D0D',
          colorText: '#F9F871',
          fontFamily: 'Roboto, sans-serif',
          fontSize: '14px',
        },
        rules: {
          '.Input': {
            color: '#F9F871',
            backgroundColor: '#0D0D0D',
            fontSize: '16px',
            border: '2px solid #F9F871',
          },
          '.AccordionItem': {
            borderRadius: '16px',
            border: '4px solid #F9F871',
            fontSize: '18px',
            fontWeight: 'bold',
          },
          '.Label': {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#F9F871'
          }
        }
      };
      
      const clientSecret = await generatePayment(amountStripe);
      elements.value = stripe.value.elements({ appearance, loader: 'always', clientSecret });

      const paymentElementOptions = {
        paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
        layout: {
          type: 'accordion',
          spacedAccordionItems: true
        }
      };

      // email field
      const linkAuthenticationElement = elements.value.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");

      const paymentElement = elements.value.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");

      paymentElement.on('loaderstart', function() {
        isLoading.value = false;
      });

    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      isButtonLoading.value = true;

      const details = await fetchLoungeDetails(loungeId);

      if (details && details.public && details.public.ranking && details.public.ranking.timeLeft){
        if (details.public.ranking.timeLeft < 60) {
          cantAddSongModalVisible.value=true;
        }  
      }
      else{

        const { error } = await stripe.value.confirmPayment({
          elements: elements.value,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: process.env.VUE_APP_FRONT_BASE_URL + `/lounge/${loungeId}/confirm-payment`,
          },
        });

        if (error) {
        // TODO
          // if (error.type === "card_error" || error.type === "validation_error") {
          //   this.showMessage(error.message);
          // } else {
          //   this.showMessage("An unexpected error occurred.");
          // }
        }

      }
      isButtonLoading.value = false;
    };

    const checkSongValidity = async (songId) => {
      try {
        songInfo.value = await trackInfo(songId);
        return songInfo.value != null;
      } catch (error) {
        console.error("Error checking song validity", error);
        return false;
      }
    };

    const handleCloseCantAddSongModal = () => {
      cantAddSongModalVisible.value = false;
      router.push(`/lounge/${loungeId}`)
    };

    const handleBack = () => {
      router.go(-1)
    };
    onMounted(async () => {
      isLoading.value = true;
      initialize();
    });

    return {
      initialize,
      handleSubmit,
      generatePayment,
      elements,
      isLoading,
      paymentMessage,
      paymentMessageVisible,
      stripe,
      amount,
      amountStripe,
      loungeId,
      isButtonLoading,
      flag,
      isAmountValid,
      isSongValid,
      checkSongValidity,
      songInfo,
      arrowBack,
      handleBack,
      votes,
      cantAddSongModalVisible,
      handleCloseCantAddSongModal
    }
  },
};
</script>

<style scoped lang="css">
.selected-shadow {
  box-shadow: 1px 1px 20px #f9f971 !important;
}

/* Variables */
* {
  box-sizing: border-box;
}

form {
  width: 100%;
  align-self: center;
  padding: 40px;
  overflow-y: auto;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

#submit {
  margin-top: 22px;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    min-width: initial;
  }
}
</style>
